
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import IbnPersonnel from "@/components/admin/IbnPersonnel.vue";

export default defineComponent({
  name: "admin-ibn-staff-list",
  components: { IbnPersonnel },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("List", ["Users", "IBN Staffs"]);
    });
  },
  created() {
    //Set page title
    document.title = "IBN Staff List | " + process.env.VUE_APP_TITLE;
  },
});
